header {
    width: calc(100% - 16px);
    height: 60px;
    background-color: rgba(0, 0, 0, .4);
    backdrop-filter: blur(10px);
    position: fixed;
    top: 8px;
    left: 8px;
    z-index: 5;
    border-radius: 20px;
    text-align: right;
}

header .logo_goat {
    position: absolute;
    left: 18px;
    top: 11px;
    height: 38px;
}

header .install_icon {
    display: inline-block;
    vertical-align: top;
    margin-top: 11px;
    margin-right: 15px;
    line-height: 40px;
    font-size: 20px;
    color: #FFF;
}

header .search_icon {
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
    margin-right: 15px;
    line-height: 40px;
    font-size: 20px;
    color: #FFF;
}

header .store_icon {
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
    margin-right: 15px;
    line-height: 40px;
    font-size: 20px;
    color: #FFF;
}

header .actionner {
    display: inline-block;
    vertical-align: top;
    margin-top: 12px;
    margin-right: 15px;
    line-height: 32px;
    height: 32px;
    font-size: 13px;
    color: #FFF;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #FFF;
    border-radius: 20px;
    padding: 0 12px;
    width: fit-content;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600;
    max-width: calc(100vw - 310px);
    text-overflow: ellipsis !important;
    overflow: hidden;
    white-space: nowrap;
}

header .goat_coins {
    align-items: center;
    position: relative;
    padding-right: 32px;
    color: #eeb400;
    border: 1px solid #000;
    background-color: #000;
    margin-right: 5px;
}

header .goat_coins img {
    position: absolute;
    right: 5px;
    bottom: 6px;
    height: 20px;
}

header .profile_entry {
    display: inline-block;
    vertical-align: top;
    margin: 10px 12px 10px 10px;
    height: 40px;
    font-size: 13px;
    color: #FFF;
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
    width: fit-content;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600;
    text-align: right;
    border-radius: 12px;
}

header .profile_entry .mobile_avatar {
    display: inline-block;
    vertical-align: top;
    width: 40px;
    height: 40px;
    background: rgb(229, 229, 229);
    background: linear-gradient(140deg, rgb(229, 229, 229) 0%, rgb(173, 173, 173) 100%);
    text-align: center;
    font-size: 22px;
    font-weight: 800;
    color: #000;
    line-height: 42px;
    border-radius: 10px;
    background-position: center center;
    background-size: cover;
    margin: 0;
}

header .active {
    color: #00FF00;
}