.menu_holder {
    position: fixed;
    width: calc(100% - 15px);
    top: 20px;
    left: 0;
    padding: 0;
    height: 115px;
    z-index: 5;
}

.menu {
    backdrop-filter: blur(10px);
    border-radius: 20px;
    height: 85px;
    display: block;
    width: fit-content;
    margin: 0 auto 0 auto;
    padding: 0 20px;
    position: relative;
    background: rgba(0, 0, 0, 0.2);
}

.menu .invite {
    background-color: #0c0e12;
    position: absolute;
    bottom: -30px;
    height: 30px;
    line-height: 30px;
    right: 40px;
    padding: 0 14px;
    width: fit-content;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #FFF;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
    border-top: 0;
}

.menu .invite:hover {
    background-color: #444d5f;
}

.menu .logo {
    display: inline-block;
    vertical-align: top;
    width: 120px;
    margin: 0 5px 0 5px;
    cursor: pointer;
}

.menu .entry {
    display: inline-block;
    vertical-align: top;
    line-height: 80px;
    font-weight: normal;
    padding: 0 20px;
    font-size: 15px;
    color: #EEE;
    cursor: pointer;
    font-weight: 600;
}

.menu .active {
    color: #00FF00 !important;
    font-weight: 700 !important;
}

.menu .entry:hover {
    color: #FFF;
}

.menu .note {
    background-color: #00FF00;
    color: #000;
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: top;
    line-height: 20px;
    margin-left: 3px;
    margin-top: 30px;
    border-radius: 20px;
    text-align: center;
    font-size: 11px;
    font-weight: 700;
}

.menu .social {
    display: inline-block;
    vertical-align: top;
    line-height: 87px;
    font-weight: normal;
    padding: 0 20px 0 0;
    font-size: 20px;
    color: #EEE;
    cursor: pointer;
    font-weight: 600;
}

.menu .social:hover {
    color: #FFF;
}

.menu .mobile {
    display: none;
}

.menu .pop_mobile {
    display: none;
}

.menu .profile {
    display: inline-block;
    margin: 18px 0 18px 20px;
    border-radius: 10px;
    min-height: 50px;
    cursor: pointer;
    text-align: right;
}

.menu .profile .avatar {
    width: 50px;
    height: 50px;
    background: rgb(229, 229, 229);
    background: linear-gradient(140deg, rgb(229, 229, 229) 0%, rgb(173, 173, 173) 100%);
    text-align: center;
    font-size: 22px;
    font-weight: 800;
    color: #000;
    line-height: 42px;
    display: inline-block;
    vertical-align: top;
    border-radius: 12px;
    margin-left: 5px;
    background-position: center center;
    background-size: cover;
}

.menu .profile .info_profile {
    height: 50px;
    text-align: right;
    line-height: 22px;
    font-size: 14px;
    word-break: break-all;
    font-weight: 600;
    color: #EEE;
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
    padding-top: 4px;
}

.menu .goat_coins{
    display: block;
    margin: 4px 0;
    line-height: 16px;
    height: 16px;
    align-items: center;
    position: relative;
    padding-right: 22px;
    font-size: 13px;
    color: #eeb400;
}
.menu .goat_coins img{
    position: absolute;
    right: 0;
    bottom: 1px;
    height: 16px;
}

@media screen and (max-width: 900px) {
    .menu_holder {
        width: calc(100% - 10px) !important;
        height: 60px;
        background: rgba(0, 0, 0, 0);
        top: auto;
        bottom: 10px;
        left: 5px;
        border-radius: 20px;
    }

    .menu {
        width: calc(100% - 40px);
        height: 60px;
        border-radius: 20px;
        background-color: #000;
        backdrop-filter: none;
    }

    .menu .logo {
        width: 80px;
        margin-top: 4px;
    }

    .menu .entry {
        display: none;
    }

    .menu .social {
        display: none;
    }

    .menu .closing {
        display: block;
        position: absolute;
        right: 20px;
        top: 10px;
        height: 45px;
        line-height: 45px;
        cursor: pointer;
        font-size: 30px;
        color: #FFF;
    }

    .menu .mobile {
        display: block;
        position: absolute;
        right: 25px;
        top: 12px;
        height: 45px;
        cursor: pointer;
    }

    .menu .mobile .line {
        display: block;
        height: 3px;
        background-color: #FFF;
        width: 30px;
        border-radius: 4px;
        margin: 6px auto;
    }

    .menu .mobile:hover .line {
        background-color: #00FF00;
    }

    .menu .pop_mobile {
        display: block;
        position: absolute;
        top: 65px;
        right: 2%;
        border: 1px solid #CACED6;
        border-radius: 15px;
        min-width: 240px;
        min-height: 200px;
        background-color: #FFF;
        padding: 8px 10px;
        text-align: right;
    }

    .menu .pop_entry {
        display: block;
        font-weight: normal;
        padding: 8px 20px;
        font-size: 15px;
        color: #434854;
        cursor: pointer;
        font-weight: 600;
        text-align: right;
    }

    .menu .pop_entry:hover {
        color: #000;
    }

    .menu .pop_social {
        display: block;
        font-weight: normal;
        padding: 8px 10px;
        font-size: 20px;
        color: #434854;
        cursor: pointer;
        font-weight: 600;
        text-align: right;
    }

    .menu .profile .info_profile {
        color: #000;
    }

    /* Mobile menu */

    .menu .mobile_logo {
        display: block;
        height: 30px;
        margin: 15px auto;
        cursor: pointer;
    }

    .menu .mobile_entry {
        display: inline-block;
        vertical-align: top;
        line-height: 64px;
        font-weight: normal;
        text-align: center;
        font-size: 20px;
        color: #DDD;
        cursor: pointer;
        font-weight: 600;
        width: 20%;
    }

    .menu .super_mobile_entry {
        display: inline-block;
        vertical-align: top;
        line-height: 65px;
        font-weight: normal;
        text-align: center;
        font-size: 20px;
        color: #FFF;
        cursor: pointer;
        font-weight: 600;
        width: 40%;
    }

    .main_action .sha {
        display: none;
    }

    .menu .super_mobile_entry .main_action {
        display: block;
        margin: 15px auto;
        border-radius: 25px;
        padding: 0 14px 0 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background-color: rgba(0, 0, 0, 0);
        color: #FFF;
        border: 1px solid #FFF;
        font-weight: 600;
        cursor: pointer;
        font-size: 14px;
        position: relative;
        width: fit-content;
    }

    .menu .super_mobile_entry .main_action:hover {
        background-color: #FAFAFA;
        color: #000;
    }

    .menu .super_mobile_entry .main_action .sha {
        display: block !important;
        position: absolute;
        left: 6px;
        top: 2px;
        width: 20px;
    }

    .menu .mobile_avatar {
        width: 40px;
        height: 40px;
        background: rgb(229, 229, 229);
        background: linear-gradient(140deg, rgb(229, 229, 229) 0%, rgb(173, 173, 173) 100%);
        text-align: center;
        font-size: 22px;
        font-weight: 800;
        color: #000;
        line-height: 42px;
        display: block;
        border-radius: 12px;
        margin-left: 5px;
        background-position: center center;
        background-size: cover;
        margin: 10px auto;
    }

    .menu .invite_mobile {
        position: absolute;
        top: -40px;
        height: 30px;
        line-height: 30px;
        right: 10px;
        padding: 0 14px;
        width: fit-content;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: #FFF;
        background-color: #000;
        border-radius: 10px;
        cursor: pointer;
        border-bottom: 0;
    }
}