footer {
    background: #1b2029;
    color: #FFF;
    border-radius: 30px;
    height: 260px;
    width: 960px;
    margin: 160px auto 30px auto;
    display: block;
    position: relative;
}

footer .logo {
    position: absolute;
    top: 10px;
    left: 20px;
    width: 100px;
}

footer .rights {
    position: absolute;
    top: 70px;
    left: 22px;
    font-size: 11px;
    font-weight: 500;
}

footer .terms_holder {
    position: absolute;
    top: 110px;
    left: 22px;
}

footer .terms_holder .terms {
    font-size: 13px;
    font-weight: 600;
    color: #EEE;
    line-height: 28px;
    cursor: pointer;
    display: block;
}

footer .terms_holder .terms:hover {
    color: #FFF;
}

footer .list_holder {
    position: absolute;
    top: 26px;
    left: 240px;
    border-left: 1px solid #EEE;
    padding-left: 40px;
}

footer .list_holder .entry {
    font-size: 13px;
    font-weight: 600;
    color: #EEE;
    line-height: 28px;
    cursor: pointer;
    display: block;
}

footer .list_holder .entry:hover {
    color: #FFF;
}

footer .social_holder {
    position: absolute;
    top: 30px;
    right: 20px;
}

footer .social_holder .social {
    font-size: 20px;
    margin: 0 8px;
    color: #EEE;
    cursor: pointer;
}

footer .social_holder .social:hover {
    color: #FFF;
}

footer .social_holder .opensea {
    width: 80px;
    margin: 8px;
    cursor: pointer;
}

footer .newsletter {
    position: absolute;
    top: 30px;
    right: 120px;
    width: 360px;
}

footer .newsletter .title {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
    padding-left: 20px;
    margin-bottom: 10px;
}

footer .newsletter .tagline {
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    padding-left: 20px;
    color: #EEE;
}

footer .newsletter .email {
    padding: 8px 20px;
    border: 1px solid #CCC;
    border-radius: 25px;
    width: 250px;
    font-size: 15px;
    font-weight: 600;
    display: block;
    margin: 10px 0;
    background-color: #FAFAFA;
}

footer .newsletter .subscribe {
    margin: -10px 0 0 0;
    border-radius: 25px;
    padding: 0 18px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #FAFAFA;
    font-weight: 600;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    font-size: 14px;
}

footer .newsletter .subscribe:hover {
    color: #FFF;
    background-color: rgba(0, 0, 0, 0);
}

@media screen and (max-width: 940px) {
    footer {
        width: calc(100% - 10px);
        height: 560px;
        margin-bottom: 40px;
        padding-bottom: 30px;
        background: #1b2029;
        color: #FFF;
    }

    footer .social_holder {
        position: absolute;
        top: 20px;
        right: 10px;
    }

    footer .terms_holder {
        border-top: 1px solid #EEE;
        position: absolute;
        top: 110px;
        left: 22px;
        width: calc(60% - 32px);
    }

    footer .terms_holder .terms {
        color: #EEE;
    }

    footer .terms_holder .terms:hover {
        color: #00FF00;
    }

    footer .list_holder .entry {
        color: #EEE;
    }
    
    footer .list_holder .entry:hover {
        color: #00FF00;
    }

    footer .list_holder {
        position: absolute;
        top: 110px;
        left: auto;
        right: 22px;
        border-left: 0;
        padding-left: 0;
        width: calc(40% - 10px);
        border-top: 1px solid #EEE;
    }

    footer .newsletter {
        position: absolute;
        top: 300px;
        left: 22px;
        right: auto;
        width: calc(100% - 44px);
        border-top: 1px solid #EEE;
        padding-top: 30px;
    }

    footer .newsletter .tagline {
        color: #EEE;
    }
    
    footer .newsletter .subscribe {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #FAFAFA;
    }
    
    footer .newsletter .subscribe:hover {
        color: #FFF;
        background-color: rgba(0, 0, 0, 0);
    }
}